import { CSS, styled } from '@/stitches.config';
import { motion } from 'framer-motion';
import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIntersection } from 'react-use';
import { EnterAnimationContext } from './context';

interface EnterAnimationProps {
  children: React.ReactNode;
  css?: CSS;
  disableAnimation?: boolean;
  enterDelayInSeconds?: number;
  onAnimationComplete?: (complete: boolean) => void;
}

export function EnterAnimation({
  children,
  css,
  disableAnimation,
  enterDelayInSeconds,
  onAnimationComplete,
}: EnterAnimationProps) {
  const intersectionRef = useRef(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const { curve, delay, yOffset, duration, threshold } = useContext(
    EnterAnimationContext
  );

  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold,
  });

  useEffect(() => {
    if (intersection?.isIntersecting && !isAnimating) {
      setIsAnimating(true);
      intersectionRef.current = null;
    }
  }, [intersection?.isIntersecting, isAnimating]);

  if (disableAnimation) {
    return <>{children}</>;
  }

  return (
    <AnimationBox
      onAnimationComplete={onAnimationComplete}
      isAnimating={isAnimating}
      enterDelayInSeconds={enterDelayInSeconds}
      yOffset={yOffset}
      duration={duration}
      delay={delay}
      curve={curve}
      ref={intersectionRef}
      css={css}
    >
      {children}
    </AnimationBox>
  );
}

interface AnimationBoxProps {
  children: React.ReactNode;
  isAnimating?: boolean;
  enterDelayInSeconds?: number;
  onAnimationComplete?: (complete: boolean) => void;
  yOffset: number;
  duration: number;
  delay: number;
  curve: unknown[];
  css?: CSS;
}

export const MotionDiv = styled(motion.div, {});

const AnimationBox = forwardRef<HTMLDivElement, AnimationBoxProps>(
  function AnimationBox(props, ref) {
    const {
      children,
      css,
      isAnimating,
      enterDelayInSeconds = 0,
      yOffset,
      duration,
      delay,
      curve,
      onAnimationComplete,
    } = props;

    const variants = {
      hidden: { opacity: 0, y: yOffset },
      visible: {
        opacity: 1,
        y: 0,
        transition: {
          duration,
          delay: enterDelayInSeconds + delay,
          ease: [curve[0], curve[1], curve[2], curve[3]],
        },
      },
    };

    return (
      <MotionDiv
        initial="hidden"
        animate={isAnimating ? 'visible' : 'hidden'}
        variants={variants}
        onAnimationComplete={
          onAnimationComplete ? () => onAnimationComplete(true) : undefined
        }
        exit={{
          opacity: 0,
          y: yOffset,
        }}
        css={css}
        ref={ref}
      >
        {children}
      </MotionDiv>
    );
  }
);
